<script>
import TablePage from '@/found/components/table_page';
import Form from '../form/form.vue';

export default {
  extends: TablePage,
  components: {
    // Modal,
    Form,
  },
  data() {
    return {
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: 'Modal',
      },
    };
  },
  mounted() {
    this.getConfigList('channel-cost-config-list');
  },
  methods: {
    setColumn(v) {
      const rows = v;
      if (rows.field === 'productCode') {
        rows.isSearch = true;
        rows.apiUrl = '/mdm/mdmPositionLevelController/selectList';
        rows.optionsKey = {
          label: 'positionLevelName',
          value: 'positionLevelCode',
        };
      }
      return rows;
    },
    modalClick({ val, row }) {
      if (val.code === 'add') {
        this.modalConfig.title = '新增';
        this.formConfig = {
          code: val.code,
          row,
        };
        this.openModal();
      } else if (val.code === 'edit') {
        this.modalConfig.title = '编辑';
        this.formConfig = {
          code: val.code,
          row,
        };
        this.formConfig.id = row.id;
        this.formConfig.edit = true;
        this.openModal();
      } else if (val.code === 'view') {
        this.modalConfig.title = '查看';
        this.formConfig = {
          code: val.code,
          row,
        };
        this.formConfig.id = row.id;
        this.formConfig.view = true;
        this.openModal();
      }
    },
  },
};
</script>
