<script>
import request from '@/found/utils/request';
import Form, { formCreate } from '@/found/components/form';
import complex from '../../../../compoment/complex/complex';

formCreate.component('complex', complex);
export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
    };
  },

  mounted() {
    this.getFormRule('channel-cost-config-form');
  },

  methods: {
    setRule(v) {
      const rowData = v;
      if (rowData.field === 'costEffectiveMethod') {
        rowData.on = {
          ...rowData.on,
          change: (e) => {
            if (e === '2') {
              // 总量控制
              this.setValue({
                effective: null,
                feeType: null,
              });
              this.hiddenFields(true, ['effective', 'feeType']);
            } else {
              this.hiddenFields(false, ['effective', 'feeType']);
            }
          },
        };
      }
      if (rowData.field === 'productCode') {
        rowData.restful = '/mdm/mdmProductController/productSelectList';
        rowData.optionsKey = {
          value: 'productCode',
          label: 'productCode',
        };
        rowData.props = {
          ...rowData.props,
          remoteParams: 'productCode',
          remote: true,
          filterable: true,
        };
        rowData.on = {
          ...rowData.on,
          getItem: (value) => {
            this.setValue({ productName: value.productName });
          },
          change: (value) => {
            this.setValue({ productCode: value });
          },
        };
      }
      if (rowData.field === 'controlType') {
        rowData.on = {
          ...rowData.on,
          change: (e) => {
            if (e === '1') {
              // 总量控制
              this.setValue({
                isSupplyBill: null,
              });
              this.hiddenFields(true, 'isSupplyBill');
            } else {
              this.hiddenFields(false, 'isSupplyBill');
            }
          },
        };
      }
      return rowData;
    },
    formComplete() {
      this.disabled(true, 'productName');
      if (this.formConfig.id) {
        this.getData(this.formConfig.id);
      }
      if (this.formConfig.code === 'edit' || this.formConfig.code === 'view') {
        this.hiddenFields(true, ['custTypes']);
      } else if (this.formConfig.code === 'add') {
        this.hiddenFields(true, ['custType']);
      }
    },

    async getData(id) {
      const { result } = await this.$http.post(
        '/dms/dms/channelFeeConfig/query',
        { id },
      );
      result.effective = {
        feeCreateDate: result.feeCreateDate,
        delayDay: result.delayDay,
        // unit: result.unit,
        delayType: result.delayType,
      };
      this.setValue({
        ...result,
      });
    },

    submit() {
      let params = this.getFormData();
      if (this.formConfig.code === 'add') {
        params.custType = params.custTypes.toString();
      }
      delete params.custTypes;
      let path = '/dms/dms/channelFeeConfig/save';
      if (this.formConfig.id) {
        path = '/dms/dms/channelFeeConfig/update';
        params.id = this.formConfig.id;
      }
      params = { ...params, ...params.effective };
      request.post(path, params).then((res) => {
        if (res.code === 200) {
          this.$message.success('操作成功！');
          this.$emit('onClose');
          this.$emit('onGetList');
        } else {
          this.$message.error(res.messge);
        }
      });
    },
  },
};
</script>
